import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import UniversalLink from "../../shared/UniversalLink";
import LogoInline from "../../../assets/svg/logo-big.inline.svg";

const BottomProducts = ({ products }) => {
  if (!products.length) return null;

  return (
    <div className="bottom-products-block">
      <h2 className={"h2"}>ตัวอย่างเกมที่ให้บริการ</h2>
      <ul className={"products-list"}>
        {products.map((product, index) => {
          const productImageData = getImage(product?.localFile);
          let linkAttrs = {};
          if (product.gatsby_image.bannerUrl.includes('http')) {
            linkAttrs = { rel : "noopener noreferrer", target: "_blank"};
          }
          return (
            <li className={"product-li"} key={"product-" + index}>
              <UniversalLink to={product.gatsby_image.bannerUrl ?? process.env.SITE_URL} {...linkAttrs}>
                <GatsbyImage alt={product.altText  ?? process.env.SITE_NAME} image={productImageData}/>
              </UniversalLink>
            </li>
          );
        })}
      </ul>
      <LogoInline/>
    </div>
  );
};

export default BottomProducts;

BottomProducts.propTypes = {
  products: PropTypes.array,
};
