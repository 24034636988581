import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";

const HomeBenefits = ({ benefits, position }) => {
  return (
    <div className="home-benefits-wrapper">
      <div className={"text-part"} dangerouslySetInnerHTML={{ __html: benefits }}/>
      <div className={"img-wrap"}>
        {position === 1 ?
          <StaticImage
            src={"../../../assets/images/home/benefits.png"}
            alt="Benefits"
            placeholder="blurred"
            width={455}
            quality={100}
          /> :
          <StaticImage
            src={"../../../assets/images/home/benefits_bottom.png"}
            alt="Benefits"
            placeholder="blurred"
            width={419}
            quality={100}
          />
        }
      </div>
    </div>
  );
};

export default HomeBenefits;

HomeBenefits.propTypes = {
  benefits: PropTypes.string,
  position: PropTypes.number
};
