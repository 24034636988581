import React from "react";
import PropTypes from "prop-types";
import HighScoreInline from "../../../assets/svg/components/high-score.inline.svg";
import MultiPlayerInline from "../../../assets/svg/components/multiplayer.inline.svg";
import AdjustRtpInline from "../../../assets/svg/components/adjust-rtp.inline.svg";
import HdInline from "../../../assets/svg/components/hd.inline.svg";
import GliInline from "../../../assets/svg/components/gli-cert.inline.svg";
import ProbabilityInline from "../../../assets/svg/components/probability.inline.svg";

const WhyChooseUs = ({ text }) => {
  return (
    <div className="home-why-us">
      <div className={"text-part"} dangerouslySetInnerHTML={{ __html: text }}/>
      <div className={"img-wrap"}>
        <ul className={"why-ul"}>
          <li>
            <HighScoreInline/>
            <span>มีเรตคะแนนสูง</span>
          </li>
          <li>
            <MultiPlayerInline/>
            <span>เล่นได้หลายคนพร้อมกัน</span>
          </li>
          <li>
            <AdjustRtpInline/>
            <span>มีอัตราจ่ายเงินรางวัล<br/>สูงถึง 88 - 98%</span>
          </li>
          <li>
            <HdInline/>
            <span>ภาพและเสียง<br/>มีคุณภาพสูง คมชัด</span>
          </li>
          <li>
            <GliInline/>
            <span>มีใบรับรองจาก GLI</span>
          </li>
          <li>
            <ProbabilityInline/>
            <span>ระบบการสุ่ม RNG<br/>ที่มีมาตรฐาน</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhyChooseUs;

WhyChooseUs.propTypes = {
  text: PropTypes.string,
};
