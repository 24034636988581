import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import UniversalLink from "../../shared/UniversalLink";
import { Carousel } from 'react-responsive-carousel';

const SliderDesktopMob = ({ slidesDesktop, slidesMobile, isMobile }) => {
  if (!slidesDesktop.length) return null;

  return (
    <div className="slides-block top-banner-block">
      {!isMobile ?
        <Carousel showThumbs={false}>
          {slidesDesktop.map((slide, index) => {
            const slideImageData = getImage(slide?.localFile);
            let linkAttrs = {};
            if (slide.gatsby_image.bannerUrl.includes('http')) {
              linkAttrs = { rel : "noopener noreferrer", target: "_blank"};
            } else if (slide.gatsby_image.bannerUrl.includes('#')) {
              linkAttrs = { rel : "noopener noreferrer"};
            }
            return (
              <div className={"multi-slider__slide"} key={"slide-" + index}>
                <UniversalLink to={slide.gatsby_image.bannerUrl ?? process.env.SITE_URL} {...linkAttrs}>
                  <GatsbyImage alt={slide.altText  ?? process.env.SITE_NAME} image={slideImageData}/>
                </UniversalLink>
              </div>
            );
          })}
        </Carousel> :
        <Carousel showThumbs={false}>
          {slidesMobile.map((slide, index) => {
            const slideImageData = getImage(slide?.localFile);
            let linkAttrs = {};
            if (slide.gatsby_image.bannerUrl.includes('http')) {
              linkAttrs = { rel : "noopener noreferrer", target: "_blank"};
            } else if (slide.gatsby_image.bannerUrl.includes('#')) {
              linkAttrs = { rel : "noopener noreferrer"};
            }
            return (
              <div className={"multi-slider__slide"} key={"slide-" + index}>
                <UniversalLink to={slide.gatsby_image.bannerUrl ?? process.env.SITE_URL} {...linkAttrs}>
                  <GatsbyImage alt={slide.altText  ?? process.env.SITE_NAME} image={slideImageData}/>
                </UniversalLink>
              </div>
            );
          })}
        </Carousel>
      }

    </div>
  );
};

export default SliderDesktopMob;

SliderDesktopMob.propTypes = {
  slidesDesktop: PropTypes.array,
  slidesMobile: PropTypes.array,
  isMobile: PropTypes.bool,
};
