import React from "react";
import { graphql } from 'gatsby';
import Layout from "../components/shared/Layout";
import GetWindowWidth from "../components/shared/helpers/GetWindowWidth";
//import TopBanner from "../components/Content/Home/TopBanner";
import HomeDesktopSlider from "../components/Content/Home/HomeSliderResponsive";
import Steps from "../components/Content/Home/Steps";
import Benefits from "../components/Content/Home/Benefits";
import WhyChooseUs from "../components/Content/Home/WhyChooseUs";
import BottomProducts from "../components/Content/Home/BottomProducts";
import "../assets/styles/pages/home.scss";


import SEO from "../components/SEO/SEO";

const ClientLazyGames = React.lazy(() =>
  import("../components/Content/Home/VideoGamesTabs")
);

const HomePage = ({ data }) => {
  const { home } = data;
  const { seo, gatsby_home } = home;
  const { bannerDesktop, bannerMobile, benefits, whyChooseUs, benefitsBottom, bottomBanners } = gatsby_home;

  const windowWidth = GetWindowWidth();
  const isMobile = windowWidth <= 600;
  const isSSR = typeof window === "undefined"; //needed for lazy

  return (
    <Layout>
      <SEO title={seo.title ?? process.env.SITE_TITLE}
           description={seo.metaDesc}/>
      <div className={"wrapper"}>
        {/*<TopBanner isMobile={isMobile} desktopImage={bannerDesktop} mobileImage={bannerMobile}/>*/}
        <HomeDesktopSlider slidesDesktop={bannerDesktop} slidesMobile={bannerMobile} isMobile={isMobile}/>
        <Steps/>
        <Benefits benefits={benefits} position={1}/>
        <WhyChooseUs text={whyChooseUs}/>
        <Benefits benefits={benefitsBottom} position={2}/>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ClientLazyGames/>
          </React.Suspense>
        )}

        {/*<VideoGamesTabs/>*/}
        <BottomProducts products={bottomBanners}/>
      </div>
    </Layout>

  )
};

export default HomePage;

export const pageQuery = graphql`
  query homeData {
    home: wpPage(slug: {eq: "home"}) {
      ...HomeData
    }
  }  
`;
