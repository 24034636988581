import React from "react";
import Register from "../../../assets/svg/components/register.inline.svg";
import Deposit from "../../../assets/svg/components/deposit.inline.svg";
import Enjoy from "../../../assets/svg/components/enjoy.inline.svg";
import Arrow from "../../../assets/svg/components/step-arrow.inline.svg";

const HomeSteps = () => {
  return (
    <div className="home-steps-wrapper">
      <a className={"step step-1"} href={process.env.LINE_LINK}
         target="_blank" rel="noopener noreferrer" aria-label="Line">
        <Register/>
        <div className="text-block">
          <span className="step-title">1. สมัคร GCLUB</span>
          <span className="step-details">
            แอดไลน์ LINE@Lnwasiathailand หรือ Tel. {process.env.PHONE_TO_CALL}
          </span>
        </div>
        <Arrow/>
      </a>
      <a className={"step step-2"} target="_blank" rel="noopener noreferrer"
         href={'https://www.gclub-casino.com/banking/'}>
        <Deposit/>
        <div className="text-block">
          <span className="step-title">2. ฝากเงินออนไลน์</span>
          <span className="step-details">
            ติดต่อฝ่าย Call Center เพื่อรับรายละเอียดช่องทางการฝากเงิน
          </span>
        </div>
        <Arrow/>
      </a>
      <a className={"step step-3"} target="_blank" rel="noopener noreferrer"
         href={'https://www.gclub-casino.com/gclub-download/'}>
        <Enjoy/>
        <div className="text-block">
          <span className="step-title">3. เล่นคาสิโนได้เงินจริง</span>
          <span className="step-details">
            รับ Username และ Password พร้อมเล่นเกมได้เลย
          </span>
        </div>
        <Arrow/>
      </a>
    </div>
  );
};

export default HomeSteps;
